import React, { ReactNode } from 'react';
import Html from '@components/common/Html';
import Text from '@components/common/text';
import FileList from '../../common/FileList';
import { AttachFileDto } from '@domain/rs/match/message/MatchingMessageListRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './messageContents.module.scss';
const cx = classnames.bind(styles);

interface MessageContentsProps {
  title: string;
  message: string;
  decisionDueDateTimeValue: string | null;
  decisionMatchingDueDateTimeValue: string | null;
  fixedGuideMessage?: ReactNode | string;
  isDangerousHtml?: boolean;
  isMatching?: boolean;
  attachFiles?: AttachFileDto[];
  positionSn?: number | null;
}

const AccLightRequestMessageContent = ({
  title,
  message,
  decisionDueDateTimeValue,
  decisionMatchingDueDateTimeValue,
  fixedGuideMessage,
  isDangerousHtml,
  isMatching,
  attachFiles,
  positionSn,
}: MessageContentsProps) => {
  return (
    <>
      <strong className={cx('title')}>{title}</strong>
      {isDangerousHtml ? (
        <Html className={cx('message')} html={message} />
      ) : (
        <div className={cx('message')}>{message}</div>
      )}
      <FileList positionSn={positionSn} attachFiles={attachFiles} />

      <div className={cx('decisionDueDate')}>
        <div className={cx('label')}>
          <Text fontStyle={FontStyle.B2P_B} color={Colors.C_COOL_GRAY_90}>
            제출 기한
          </Text>
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            {decisionDueDateTimeValue}
          </Text>
        </div>
        <div className={cx('notice')}>
          정해진 기한까지 제출하지 않을 경우 제출이 불가능합니다.
          <br />
          반드시 제출 마감 전까지 역량검사 응시를 완료해 주세요.
        </div>

        {fixedGuideMessage}
      </div>
    </>
  );
};

export default AccLightRequestMessageContent;
