import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@common/toast';
import Icon from '@common/assets';
import Text from '@components/common/text';
import { useSubmitApply } from '@repository/match';
import { useMessageActions, useMessageStore } from '@stores/MessageStore';
import { resultModalStateType } from '../phsRequest';
import { useAccResultProgressComplete } from '../query/useAccResultsProgressComplete';
import ResultSubmit from '../resultSubmit';
import ResultModals from '../resultModals';
import MessageContents from '../messageContents';
import { AccProgressCompleteVO } from '@domain/vo/acca/test/AccProgressCompleteListVO';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import { MatchingTalkApplyType } from '@domain/rs/match/message/MatchingMessageListRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import useDebounce from '@utils/hooks/useDebounce';

import { RouteUrl, V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './accRequest.module.scss';
const cx = classnames.bind(styles);

interface AccRequestProps {
  companySn: number;
  positionSn: number;
  companyMessage: MatchMessageVO;
}

const AccRequest = ({ companySn, positionSn, companyMessage }: AccRequestProps) => {
  const messagesInfo = useMessageStore((state) => state.messagesInfo);
  const { fetchMessagesList } = useMessageActions();
  const Toast = useToast();

  const {
    title,
    message,
    submitApplies,
    isEndDecisionDateTime,
    decisionDueDateTimeValue,
    decisionMatchingDueDateTimeValue,
    options,
    companyAttachFiles,
  } = companyMessage;
  const [resultModalState, setResultModalState] = useState<resultModalStateType>('CONFIRM');
  const [resultType, setResultType] = useState<MatchingTalkApplyType | null>(null);
  const [resultsData, setResultsData] = useState<AccProgressCompleteVO[] | null>(null);

  const { data: ACCdata, isLoading } = useAccResultProgressComplete();

  const submitSuccess = async () => {
    if (!positionSn) return;
    Toast({
      type: 'result',
      size: 'content',
      iconType: 'info',
      content: '정말 수고하셨어요! 제출된 결과를 인사담당자님에게 바로 전달해 드릴게요.',
    });
    await fetchMessagesList(positionSn);
    modalClose();
  };

  const submitError = (e: AxiosError) => {
    switch (e?.response?.data?.errorCode) {
      case 'B908':
        setResultModalState('ERROR');
        return;
      default:
        Toast({ type: 'error', iconType: 'info', content: e?.response?.data?.message });
        setResultModalState(null);
    }
  };

  const { mutate: fetchSubmitApply } = useSubmitApply(submitSuccess, submitError);

  const getUploaded = (applyType: MatchingTalkApplyType) =>
    submitApplies.some((submitApply) => submitApply.applyType === applyType);

  const submitResult = useDebounce(
    (selectedResultSn: number) => {
      if (positionSn && selectedResultSn)
        fetchSubmitApply({ positionSn, optionSn: Number(options[0].sn), applySn: selectedResultSn });
    },
    700,
    true,
  );

  const modalConfirm = () => setResultModalState('CONFIRM');
  const modalClose = () => setResultModalState(null);
  const modalCancel = () => setResultModalState('SELECT');

  useEffect(() => {
    if (ACCdata && resultType === 'ACC') setResultsData(ACCdata.resultRecords);
  }, [resultType, ACCdata]);

  if (isLoading || !messagesInfo) return null;

  const { openYn, deleteYn } = messagesInfo;
  const isNotice = !openYn || deleteYn;

  //역량검사 제출 고정 문구 추가
  const fixedGuideMessageEl = (
    <div className={cx('guideArea')}>
      <Text fontStyle={FontStyle.B2P_B}>역량검사 준비하기</Text>

      <div className={cx('btnArea')}>
        <a
          className={cx('link')}
          href={V2Route.getInfoPreTestPreparationGuidePage()}
          target={'_blank'}
          rel="noreferrer"
        >
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            사전 준비 가이드 확인하기
          </Text>
          <Icon name="arrowRightLight" width={24} height={24} />
        </a>

        <a className={cx('link')} href={RouteUrl.ACCA_TEST_GATE} target={'_blank'} rel="noreferrer">
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            튜토리얼 연습하기
          </Text>
          <Icon name="arrowRightLight" width={24} height={24} />
        </a>
      </div>
    </div>
  );

  return (
    <div className={cx('noticeArea')}>
      {/* [D] 메세지 영역 */}
      <MessageContents
        title={title}
        message={message}
        decisionDueDateTimeValue={decisionDueDateTimeValue}
        decisionMatchingDueDateTimeValue={decisionMatchingDueDateTimeValue}
        fixedGuideMessage={fixedGuideMessageEl}
        attachFiles={companyAttachFiles}
        positionSn={positionSn}
      />

      {/* [D] 검사 제출 버튼 영역 */}
      {!getUploaded('ACC') && (isEndDecisionDateTime || isNotice) ? (
        <ResultSubmit submitType={null} setResultType={setResultType} setResultModalState={setResultModalState} />
      ) : (
        <ResultSubmit
          submitType={'ACC'}
          setResultType={setResultType}
          isUploaded={getUploaded('ACC')}
          setResultModalState={setResultModalState}
        />
      )}

      {/* [D] 검사 선택, 결정 모달 영역 */}
      <ResultModals
        companySn={companySn}
        modalConfirm={modalConfirm}
        modalClose={modalClose}
        modalCancel={modalCancel}
        resultsData={resultsData}
        resultType={resultType}
        resultModalState={resultModalState}
        submitResult={submitResult}
      />
    </div>
  );
};

export default AccRequest;
