import React from 'react';
import { useQuery } from 'react-query';
import Icon from '@common/assets';
import Text from '@components/common/text';
import { useMessageActions, useMessageStore } from '@stores/MessageStore';
import SubmitBtn from './submitBtn';
import AccLightRequestMessageContent from '../messageContents/accLightRequest';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';

import { RouteUrl, V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface AccRequestProps {
  companySn: number;
  positionSn: number;
  companyMessage: MatchMessageVO;
}

const AccLightRequest = ({ companySn, positionSn, companyMessage }: AccRequestProps) => {
  const messagesInfo = useMessageStore((state) => state.messagesInfo);
  const { fetchMessagesList } = useMessageActions();
  const { title, message, decisionDueDateTimeValue, decisionMatchingDueDateTimeValue, companyAttachFiles } =
    companyMessage;

  useQuery('messages', () => fetchMessagesList(positionSn), {
    refetchOnWindowFocus: true, // 이 옵션이 활성화되면 창이 포커스를 받을 때마다 refetch를 수행합니다.
  });

  if (!messagesInfo) return null;

  //역량검사 제출 고정 문구 추가
  const fixedGuideMessageEl = (
    <div className={cx('guideArea')}>
      <Text fontStyle={FontStyle.B2P_B}>역량검사 준비하기</Text>

      <div className={cx('btnArea')}>
        <a
          className={cx('link')}
          href={V2Route.getInfoPreTestPreparationGuidePage()}
          target={'_blank'}
          rel="noreferrer"
        >
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            사전 준비 가이드 확인하기
          </Text>
          <Icon name="arrowRightLight" width={24} height={24} />
        </a>

        <a className={cx('link')} href={RouteUrl.ACCA_TEST_GATE} target={'_blank'} rel="noreferrer">
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            튜토리얼 연습하기
          </Text>
          <Icon name="arrowRightLight" width={24} height={24} />
        </a>
      </div>
    </div>
  );

  return (
    <div className={cx('noticeArea')}>
      {/* [D] 메세지 영역 */}
      <AccLightRequestMessageContent
        title={title}
        message={message}
        decisionDueDateTimeValue={decisionDueDateTimeValue}
        decisionMatchingDueDateTimeValue={decisionMatchingDueDateTimeValue}
        fixedGuideMessage={fixedGuideMessageEl}
        attachFiles={companyAttachFiles}
        positionSn={positionSn}
      />

      {/* [D] 검사 제출 버튼 영역 */}
      <SubmitBtn positionSn={positionSn} companyMessage={companyMessage} />
    </div>
  );
};

export default AccLightRequest;
