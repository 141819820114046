import React, { FC, useEffect } from 'react';

import Modal from '@components/common/modal';
import TermsModal from '@components/common/modals/accLightModal/contents/AccResults/terms';
import AccLightSelectModal from '@components/common/modals/accLightModal/contents/AccResults';
import AccLightContinueModal from '@components/common/modals/accLightModal/contents/continue';
import { useApplicationPositionAccStatus } from '@repository/match';
import { useAccLightApplyHistory } from '@repository/accLight/useAccLight';
import { useMessageActions, useMessageStore } from '@stores/MessageStore';
import { useTermsCheckNewMutation } from '@components/acca/test/query/useTerms';
import MessageButton from '../../../../common/button';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import { AccLightStatusTypeEnum } from '@domain/rs/match/PositionAccStatusRs';
import { FontStyle } from '@domain/constant/fontStyle';
import { TERMS } from '@domain/constant/v1/termsType';
import useModals from '@utils/hooks/useModals';
import SessionStorageUtil from '@utils/storage/sessionStorage';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface SubmitBtnProps {
  positionSn: number;
  companyMessage: MatchMessageVO;
}

const SubmitBtn: FC<SubmitBtnProps> = ({ positionSn, companyMessage }) => {
  const messagesInfo = useMessageStore((state) => state.messagesInfo);
  const { fetchMessagesList } = useMessageActions();
  const { submitApplies, isEndDecisionDateTime } = companyMessage;
  const { openModal, isVisible, closeModal, modalState } = useModals(['SELECT', 'CONTINUE', 'TEST_TERMS', 'NEW_APPLY']);
  const { data: accLightStatus } = useApplicationPositionAccStatus(positionSn);
  const isNotice = !messagesInfo?.openYn || messagesInfo?.deleteYn;
  const isSubmitted = !!submitApplies.length;

  const isLoadingSubmitStatus =
    SessionStorageUtil.getItem('accLightStatus') === companyMessage.sn ? !companyMessage.submitApplies?.length : false;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isLoadingSubmitStatus) {
      // 1초마다 fetchMessagesList 호출
      intervalId = setInterval(() => {
        fetchMessagesList(positionSn);
      }, 1000);
    } else {
      SessionStorageUtil.removeItem('accLightStatus');
    }

    // 컴포넌트 언마운트 시나 isLoadingSubmitStatus가 false가 될 때 타이머 정리
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoadingSubmitStatus]);

  const disabled = !!submitApplies.length || isEndDecisionDateTime || isNotice || isLoadingSubmitStatus;
  const { data: accLightData, isLoading } = useAccLightApplyHistory(
    { positionSn, messageRequestYn: true },
    { enabled: !disabled },
  );
  const isResults = accLightData?.historyList.length;

  const { mutateAsync: checkTermsMutateAsync } = useTermsCheckNewMutation();

  const handleResultSubmitBtn = () => {
    if (!accLightStatus) return;
    if (accLightStatus?.accLiteStatus === AccLightStatusTypeEnum.IN_PROGRESS) {
      openModal('CONTINUE');
      return;
    }

    openModal('LIST');
  };

  const handleApplyBtn = async () => {
    if (!accLightStatus) return;
    if (accLightStatus?.accLiteStatus === AccLightStatusTypeEnum.IN_PROGRESS) {
      openModal('CONTINUE');
      return;
    }

    //약관 동의 여부 체크
    const { existsNewTermsAgree } = await checkTermsMutateAsync(TERMS.ACC_PRIVACY_POLICY);
    if (existsNewTermsAgree) {
      openModal('TEST_TERMS');
      return;
    }

    openModal('NEW_APPLY');
  };

  const getElements = () => {
    if (isSubmitted) {
      return (
        <MessageButton className={cx('btn')} fontStyle={FontStyle.B3_B} disabled>
          역량검사 결과 제출
        </MessageButton>
      );
    } else if (isEndDecisionDateTime)
      return (
        <MessageButton className={cx('btn')} fontStyle={FontStyle.B3_B} disabled>
          제출 기간 만료
        </MessageButton>
      );
    else if (isNotice)
      return (
        <MessageButton className={cx('btn')} fontStyle={FontStyle.B3_B} disabled>
          제출 요청 만료
        </MessageButton>
      );

    return (
      <>
        <MessageButton
          className={cx('btn')}
          fontStyle={FontStyle.B3_B}
          theme="secondary"
          onClick={handleResultSubmitBtn}
          isLoading={isLoading}
          disabled={!isResults || disabled}
        >
          기존 결과 제출
        </MessageButton>
        <MessageButton className={cx('btn')} fontStyle={FontStyle.B3_B} onClick={handleApplyBtn} disabled={disabled}>
          {isLoadingSubmitStatus ? '제출 중' : '처음부터 응시하기'}
        </MessageButton>
      </>
    );
  };

  return (
    <>
      <div className={cx('btnArea')}>{getElements()}</div>

      <Modal visible={!!modalState}>
        <AccLightSelectModal
          messageSn={companyMessage.sn}
          initialModal={modalState ?? 'LIST'}
          visible={isVisible('SELECT') || isVisible('NEW_APPLY') || isVisible('LIST')}
          onClose={closeModal}
          positionSn={positionSn}
          dimmed={false}
        />

        <AccLightContinueModal
          visible={isVisible('CONTINUE')}
          onClose={closeModal}
          positionSn={positionSn}
          dimmed={false}
        />

        <TermsModal
          termsType={TERMS.ACC_PRIVACY_POLICY}
          visible={isVisible('TEST_TERMS')}
          onClose={closeModal}
          onConfirm={() => openModal('NEW_APPLY')}
          dimmed={false}
        />
      </Modal>
    </>
  );
};

export default SubmitBtn;
